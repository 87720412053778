<template>
  <card>
    <h5 slot="header" class="title">Edit Profile</h5>
    <div class="row">
      <div class="col-md-4 pl-md-1">
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
            <InputText
              id="company"
              type="text"
              :disabled="true"
              v-model="model.company"
            />
            <label for="company">Company</label>
          </span>
        </div>
      </div>
      <div class="col-md-4 pl-md-1">
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
            <InputText
              id="email"
              type="email"
              :disabled="true"
              v-model="model.email"
            />
            <label for="email">Email</label>
          </span>
        </div>
      </div>
      <div class="col-md-4 pl-md-1">
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
            <InputText id="first_name" type="text" v-model="model.first_name" />
            <label for="first_name">First Name</label>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 pl-md-1">
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
            <InputText id="last_name" type="text" v-model="model.last_name" />
            <label for="last_name">Last Name</label>
          </span>
        </div>
      </div>
      <div class="col-md-4 pl-md-1">
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
            <InputText
              id="phone"
              mode="decimal"
              :useGrouping="false"
              v-model="phone"
            />
            <label for="phone">Phone</label>
          </span>
        </div>
      </div>
      <div class="col-md-4 pl-md-1">
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
            <InputText id="address" type="text" v-model="model.address" />
            <label for="address">Address</label>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 pl-md-1">
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
            <InputText id="city" type="text" v-model="model.city" />
            <label for="city">City</label>
          </span>
        </div>
      </div>
      <div class="col-md-4 pl-md-1">
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
            <InputText id="country" type="text" v-model="model.country" />
            <label for="country">Country</label>
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-8">
        <base-input>
          <label>About Me</label>
          <textarea rows="4" cols="80"
                    class="form-control"
                    placeholder="Here can be your description"
                    v-model="model.about">

              </textarea>
        </base-input>
      </div>
    </div> -->
    {{render}}

    <base-button slot="footer" type="primary" v-on:click="getDados" fill
      >Save</base-button
    >
  </card>
</template>
<script>
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import InputNumber from "primevue/inputnumber";

import { UsersServices } from "../../services/UsersServices";

export default {
  components: {
    InputText,
    Password,
    InputNumber,
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
    phone: null,
  },

  computed:{
    render:function(){
      this.phone = this.model.phone;
    }
  },

  watch: {
    phone(newVal, oldVal) {
      try {
        var numberPattern = /\d+/g;
        var mat = newVal.match(numberPattern);
        this.phone = mat;
      } catch {
        return "";
      }
    },
  },

  methods: {
    getDados() {
      var payload = {
        first_name: this.model.first_name,
        last_name: this.model.last_name,
        email: this.model.email,
        city: this.model.city,
        country: this.model.country,
        address: this.model.address,
        phone: this.phone,
      };

    

      var id = this.$store.getters.getUser.user_id;

      UsersServices.update(payload, id)
        .then((result) => {
          this.$store.dispatch('alert_success',{message:'Updated successfully',time:1200})
        })
        .catch((err) => {
          this.$store.dispatch('alert_success',{message:'Internal error',time:1200})
        });
    },
  },
};
</script>
<style>
</style>
