<template>
  <div>
    {{ Switch }}
    <Panel :header="advanced.title" class="p-0 m-0 mt-2 mb-2" :collapsed="collapsed" :toggleable="true">
      <template #header>
        <p style="margin: 0px">{{ advanced.title }}</p>
        <div style="flex: 1; text-align: end; padding-right: 15px">
          <InputSwitch
            v-model="advanced.switch"
            style="vertical-align: middle"
          />
        </div>
      </template>
      <div :class="'row p-0 top' + theme + ' ' + theme">
        <div class="col pt-3 pb-3 border-bottom">
          <p>{{ method }} url: {{ url }}</p>
        </div>
      </div>
      <div :class="'row p-0 top' + theme + ' ' + theme">
        <div class="col pt-3 pb-3 border-bottom">
          <p>Headers</p>
        </div>
      </div>
      <div v-for="(item, idx) in header" :key="idx">
        <div :class="'row p-0 top' + theme + ' ' + theme">
          <div class="col pt-3 pb-3 border-bottom border-right">
            <p>{{ item.key }}</p>
          </div>
          <div class="col pt-3 pb-3 border-bottom">
            <p>{{ item.value }}</p>
          </div>
        </div>
        <div :class="'row ' + theme">
          <div class="col">
            <PrismCode :theme="theme" :text="item.text"></PrismCode>
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>
<script>
import Panel from "primevue/panel";
import PrismCode from "./PrismCode.vue";
import InputSwitch from "primevue/inputswitch";
import "./Css/PanelApi.css";

export default {
  components: {
    Panel,
    PrismCode,
    InputSwitch,
  },

  props: ["title", "data", "toggleable", "method", "url", "header", "collapsed"],

  data() {
    return {
      advanced: {
        title: null,
        switch: false,
      },
      theme: "white",
    };
  },

  created() {
    this.advanced.title = this.title;
  },

  computed: {
    Switch() {
      if (this.advanced.switch == false) {
        this.theme = "white";
      } else if (this.advanced.switch == true) {
        this.theme = "black";
      }
    },
  },
};
</script>
<style>
</style>