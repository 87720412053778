import axios from "axios";
import env from "../env.js";

var url = env.API+"/logs/";

export const LogsServices = {
  async onPage(selected, first, row){
    return axios.get(url+selected+"/"+first+"/"+row);
  },

  async selectIp(selected, offset, limit){
    return axios.get(url+selected+"/"+offset+"/"+limit);
  }
};
export default { LogsServices };