<template>
    <Dialog :header="header" :visible.sync="displayModal" :style="{width: '50vw'}" :modal="true">
      <div class="col-md-12 p-0">
        <p class="danger-text">{{message}}</p>
        <div class="col-md-12 p-0">
        
        </div>
      </div>
      <template #footer>
          <base-button type="warning" @click="closeModal" fill>Cancel</base-button>
          <base-button type="danger" @click="deleteModal(id)" fill>Delete</base-button>
      </template>
    </Dialog>
</template>
<script>
import {CredentialServices} from '../services/CredentialsServices';
import Dialog from 'primevue/dialog';
import UsersServices from '../services/UsersServices';
import axios from 'axios';
import env from "../env.js";

export default {
  name: "close-button",
  components:{
      Dialog
  },
  props: {
    message: {
      type: [String],
      description: "Delete Message"
    },
    header: {
      type: [String],
      description: "Header Text"
    },
  },
  data(){
      return{
          displayModal: false,
          id: null
      }
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
    openModal(id) {
        this.id = id;
        this.displayModal = true;
    },
    closeModal() {
        this.displayModal = false;
    },
    deleteModal(id){
      this.$emit('handler-event', id);
      this.closeModal()
    }
  }
};
</script>
<style>
</style>
