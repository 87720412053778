<template>
  <div>
    <prism-editor
      :class="theme"
      v-model="code"
      :highlight="highlighter"
      line-numbers
      readonly="false"
    ></prism-editor>
    
  </div>
</template>
<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";

import "./Css/WhiteTheme.css"
import "./Css/BlackTheme.css"

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

export default {
  components: {
    PrismEditor,
  },

  props:['theme', 'text'],

  data() {
    return {
      code: null,
    };
  },

    created() {
        this.code = this.text;
    },

  methods: {
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
  },
};
</script>
<style>
   
</style>