import axios from "axios";
import env from "../env.js";

export const CredentialServices = {
  async new(body) {
    return axios.post(env.API+"/credentials/new",body);
  },
  async delete(credential_id) {
    //console.log(body)
    return axios.delete(env.API+"/credentials/delete/"+credential_id);
  },
  async single(credential_id){
    return axios.get(env.API+"/credentials/single"+credential_id)
  },
  async update(payload){
    return axios.put(env.API+"/credentials/update/", payload)
  }
};
export default { CredentialServices };