<template>
  <div class="row">
    <div class="col-md-12">
      <PanelApi title="Custom Api" method="Post" url="https://api.lxmail.uy/lxmail/custom" :header="headerApiCustom" :collapsed="false"></PanelApi>
    </div>

    <div class="col-md-12">
      <card :title="mailsList.title">
        <base-button
          slot="header-button"
          type="primary"
          class="btn-auto"
          @click="openModal"
          fill
          >Add Credentials</base-button
        >

        <div class="table-responsive">
          <base-table
            :data="userStore.credentials"
            :columns="mailsList.columns"
            thead-classes="text-primary"
          >
            {{ user }}
            <template slot-scope="{ row }">
              <td>
                <p>{{ row.ip }}</p>
              </td>
              <td>
                <p>{{ row.token }}</p>
              </td>
              <td>
                <p>{{ row.sent }}</p>
              </td>
              <td>
                <p>{{ row.remaining }}</p>
              </td>
              <td class="td-actions text-right">
                <base-button
                  type="link"
                  aria-label="edit button"
                  @click="hideAndShow(row)"
                >
                  <i class="tim-icons icon-bulb-63"></i>
                </base-button>
                <base-button
                  type="link"
                  aria-label="edit button"
                  @click="deleteCredential(row)"
                >
                  <i class="tim-icons text-danger icon-trash-simple"></i>
                </base-button>
              </td>
            </template>
          </base-table>
        </div>
      </card>
    </div>
    <!-- <div class="col-md-4">
      <user-card :user="user"></user-card>
    </div> -->
    <Dialog
      header="Add Credentials"
      :visible.sync="credential_modal.show"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div
        class="col-md-12 p-0"
        v-if="
          userStore.plan &&
          userStore.plan.quantity > -1 &&
          userStore.credentials.length >= userStore.plan.quantity
        "
      >
        <p class="danger-text">
          You do not have credentials available, upgrade your plan to get extra
          credentials.
        </p>
      </div>
      <div class="col-md-12 p-0" v-else>
        <div class="col-md-4 pl-md-1">
          <div class="p-field pt-4">
            <span class="p-float-label">
              <InputText
                id="ip"
                class="w-100"
                type="text"
                v-model="credential_modal.data.ip"
              />
              <label for="ip">IP</label>
            </span>
          </div>
        </div>
      </div>
      <template #footer>
        <base-button type="warning" @click="closeModal" fill
          >Cancel</base-button
        >
        <base-button
          type="success"
          v-if="
            userStore.plan &&
            userStore.plan.quantity >= -1 &&
            userStore.credentials.length < userStore.plan.quantity
          "
          @click="saveCredentials"
          fill
          >Save</base-button
        >
      </template>
    </Dialog>
    <DeleteAlert
      ref="delete"
      :header="'Delete'"
      :message="'The credential will be permanently deleted, do you want to continue?'"
      @handler-event="DeleteEvent">
    </DeleteAlert>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import Dialog from "primevue/dialog";
import { UsersServices } from "../services/UsersServices";
import { CredentialServices } from "../services/CredentialsServices";
import DeleteAlert from "../components/DeleteAlert";
import PanelApi from "./Components/PanelApi.vue"
import InputText from 'primevue/inputtext'
import "./Css/Credentials.css";

const tableColumns = ["IP", "Token", "Sent", "Remaining"];

export default {
  components: {
    BaseTable,
    Dialog,
    DeleteAlert,
    PanelApi,
    InputText
  },
  data() {
    return {
      id: null,
      credential_modal: {
        show: false,
        data: {
          ip: null,
        },
      },
      mailsList: {
        title: "Credentials List",
        columns: [...tableColumns],
        data: null,
      },
      userStore: this.store(),
      headerApiCustom:[{
        key: 'Authorization',
        value: 'Credential token',
        text: `
{
    site_title: "Site Title",
    site: "Site Name",
    topic: "Topic",
    to_email: "Recipient email",
    form_name: "Form ID or class",
    headers: {
        from_email: "From email",
        bcc_emails: "Bcc emails (optional)"
    },
    body: "Message (HTML or Plain Text)"
    }
}` //Tabs change in code view too
      }],
    };
  },

  created() {
    this.userStore = this.store();
  },

  beforeUpdate() {
    this.userStore.credentials.map((item) => {
      item.hidden = true;
      var priv = this.maskToken(item.token);

      if (item.hidden == true) {
        //NOT HIDDEN
        item.tokenP = item.token;
        item.token = priv;

        item.hidden = false;
      } else {
        //HIDDEN

        if (item.tokenP) {
          item.token = item.tokenP;
          item.hidden = true;
        } else {
          item.token = "";
          item.hidden = true;
        }

        item.hidden = true;
      }
    });
  },

  computed: {
    user() {
      this.userStore = this.store();
      
    },
  },

  methods: {
    store() {
      let user = this.$store.state.Session.user;

      if (user && user.user_id) {
        //Similar al map pero filter
        let credentials = user.credentials.map((credential) => {
          if (credential.daily_email_amount < 0) {
            credential.remaining = "Ilimitado";
          } else {
            if (credential.emails_sent) {
              credential.remaining =
                user.plan.daily_email_amount - credential.emails_sent;
            } else {
              credential.remaining = user.plan.daily_email_amount; //- credential.emails_sent;
            }
          }
          credential.remaining = credential.remaining;
          credential.sent = credential.emails_sent;
          return credential;
        });
        user.credentials = credentials;
      }

      return user;
    },
    DeleteEvent(id) {
      // HACER EL DELETE
      CredentialServices.delete(id)
        .then((response) => {
          this.userStore.credentials = this.userStore.credentials.filter(
            (credential) => {
              if (id == credential.credential_id) {
                this.$store.dispatch("alert_error", {
                  message: "Successfully deleted",
                  time: 1200,
                });
                return false;
              }
              return true;
            }
          );
        })
        .catch((error) => {
          this.$store.dispatch("alert_error", { message: error, time: 5000 });
        });
    },
    openModal() {
      this.credential_modal.show = true;
    },
    closeModal() {
      this.credential_modal.show = false;
    },
    saveCredentials() {
      if (this.credential_modal.data.ip != null) {
        const body = {
          ip: this.credential_modal.data.ip,
          user_id: this.userStore.user_id,
          plan_id: this.userStore.plan.plan_id,
          daily_email_amount: this.userStore.plan.daily_email_amount,
        };
        CredentialServices.new(body)
          .then((credential) => {
            this.credential_modal.show = false;
            this.credential_modal.data = Object.assign({}, { ip: null });

            //Atualizando o store
            UsersServices.single(this.userStore.user_id)
              .then((result) => {
                //Passando o result para o store
                this.$store.commit("saveUser", result.data.data);
                this.$store.dispatch("alert_success", {
                  message: "Successfully added",
                  time: 1200,
                });
              })
              .catch((err) => {});

            //Atualizando o view
            this.$router
              .push({ path: "/credentials" })
              .then((result) => {})
              .catch((err) => {});
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store.dispatch("alert_error", {
                message: error.response.message,
                time: 1200,
              });
            }
          });
      } else {
        this.$store.dispatch("alert_error", {
          message: "Something has not been filled",
          time: 1200,
        });
      }
    },
    deleteCredential(row) {
      this.$refs.delete.openModal(row.credential_id);
    },
    hideAndShow(row) {
      var id = row.credential_id;
      const teste = this.userStore.credentials.map((item) => {
        //isssso!!!
        if (item.credential_id == id) {
          var priv = this.maskToken(item.token);

          if (item.hidden == true) {
            //NOT HIDDEN
            item.tokenP = item.token;
            item.token = priv;

            item.hidden = false;
          } else {
            //HIDDEN

            if (item.tokenP) {
              item.token = item.tokenP;
              item.hidden = true;
            } else {
              item.token = "";
              item.hidden = true;
            }

            item.hidden = true;
          }
        }
      });
    },
    maskToken(token) {
      var numCaracteres = token.length;
      var mask = "";
      for (var i = 0; i < numCaracteres; i++) {
        mask += "*";
      }

      return mask;
    },
  },
};
</script>
