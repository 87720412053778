var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('PanelApi',{attrs:{"title":"Custom Api","method":"Post","url":"https://api.lxmail.uy/lxmail/custom","header":_vm.headerApiCustom,"collapsed":false}})],1),_c('div',{staticClass:"col-md-12"},[_c('card',{attrs:{"title":_vm.mailsList.title}},[_c('base-button',{staticClass:"btn-auto",attrs:{"slot":"header-button","type":"primary","fill":""},on:{"click":_vm.openModal},slot:"header-button"},[_vm._v("Add Credentials")]),_c('div',{staticClass:"table-responsive"},[_c('base-table',{attrs:{"data":_vm.userStore.credentials,"columns":_vm.mailsList.columns,"thead-classes":"text-primary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('td',[_c('p',[_vm._v(_vm._s(row.ip))])]),_c('td',[_c('p',[_vm._v(_vm._s(row.token))])]),_c('td',[_c('p',[_vm._v(_vm._s(row.sent))])]),_c('td',[_c('p',[_vm._v(_vm._s(row.remaining))])]),_c('td',{staticClass:"td-actions text-right"},[_c('base-button',{attrs:{"type":"link","aria-label":"edit button"},on:{"click":function($event){return _vm.hideAndShow(row)}}},[_c('i',{staticClass:"tim-icons icon-bulb-63"})]),_c('base-button',{attrs:{"type":"link","aria-label":"edit button"},on:{"click":function($event){return _vm.deleteCredential(row)}}},[_c('i',{staticClass:"tim-icons text-danger icon-trash-simple"})])],1)]}}])},[_vm._v(" "+_vm._s(_vm.user)+" ")])],1)],1)],1),_c('Dialog',{style:({ width: '50vw' }),attrs:{"header":"Add Credentials","visible":_vm.credential_modal.show,"modal":true},on:{"update:visible":function($event){return _vm.$set(_vm.credential_modal, "show", $event)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('base-button',{attrs:{"type":"warning","fill":""},on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),(
          _vm.userStore.plan &&
          _vm.userStore.plan.quantity >= -1 &&
          _vm.userStore.credentials.length < _vm.userStore.plan.quantity
        )?_c('base-button',{attrs:{"type":"success","fill":""},on:{"click":_vm.saveCredentials}},[_vm._v("Save")]):_vm._e()]},proxy:true}])},[(
        _vm.userStore.plan &&
        _vm.userStore.plan.quantity > -1 &&
        _vm.userStore.credentials.length >= _vm.userStore.plan.quantity
      )?_c('div',{staticClass:"col-md-12 p-0"},[_c('p',{staticClass:"danger-text"},[_vm._v(" You do not have credentials available, upgrade your plan to get extra credentials. ")])]):_c('div',{staticClass:"col-md-12 p-0"},[_c('div',{staticClass:"col-md-4 pl-md-1"},[_c('div',{staticClass:"p-field pt-4"},[_c('span',{staticClass:"p-float-label"},[_c('InputText',{staticClass:"w-100",attrs:{"id":"ip","type":"text"},model:{value:(_vm.credential_modal.data.ip),callback:function ($$v) {_vm.$set(_vm.credential_modal.data, "ip", $$v)},expression:"credential_modal.data.ip"}}),_c('label',{attrs:{"for":"ip"}},[_vm._v("IP")])],1)])])])]),_c('DeleteAlert',{ref:"delete",attrs:{"header":'Delete',"message":'The credential will be permanently deleted, do you want to continue?'},on:{"handler-event":_vm.DeleteEvent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }