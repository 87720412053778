<template>
  <div class="row">
    <div class="col-md-8">
      <edit-profile-form :model="userStore">
      </edit-profile-form>
    </div>
    <div class="col-md-4">
      <user-card :user="userStore.plan"></user-card>
    </div>
  </div>
</template>
<script>
  import EditProfileForm from './Profile/EditProfileForm';
  import UserCard from './Profile/UserCard';
  import {UsersServices} from "../services/UsersServices";
  
  export default {
    components: {
      EditProfileForm,
      UserCard
    },
    data() {
      return {
        model: {
          company: 'Creative Code Inc.',
          email: 'mike@email.com',
          firstName: 'Mike',
          lastName: 'Andrew',
          address: 'Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09',
          city: 'Melbourne',
          country: 'Australia',
        },
        plan: {}
      }
    },
    created() {
      
    },
    computed: {
      userStore() {
        return this.$store.state.Session.user
      }
    }
  }
</script>
<style>
</style>
