<template>
  <div class="row">
    <div class="col-md-12">
        <card :title="'Logs'">
          <el-select class="select-danger"
             placeholder="Select IP"
             :label="'Select IP'"
             v-model="selects.selected"
             @change="SelectIp">
                <el-option v-for="option in userStore.credentials"
                        class="select-danger"
                        :value="option.credential_id"
                        :label="option.ip"
                        :key="option.ip">
                </el-option>
            </el-select>
        </card>
    </div>
    <div class="col-12" v-if="selects.selected">
        <card :title="logsList.title">
            <div class="table-responsive">
                <base-table :data="logsList.data"
                            :columns="logsList.columns"
                            thead-classes="text-primary">
                </base-table>
            </div>
        </card>
    </div>
    <div class="col-12" v-if="logsList.data.length > 0">
        <Paginator @page="onPage($event)" :first="pagination.offset" :rows="pagination.limit" :totalRecords="pagination.total" :rowsPerPageOptions="[25,100,200]"></Paginator>
    </div>
    
    <!-- <div class="col-md-4">
      <user-card :user="user"></user-card>
    </div> -->
  </div>
</template>
<script>
    import { BaseTable } from "@/components";
    import {Select, Option} from 'element-ui'
    import 'element-ui/lib/theme-chalk/index.css'
    import Paginator from 'primevue/paginator';
    import {LogsServices} from './../services/LogsServices'
    export default {
        components: {
            BaseTable,
            [Select.name]: Select,
            [Option.name]: Option,
            Paginator
        },
        data() {
        return {
            selects: {
                selected: null
            },
            logsList:{
                title:"Log List",
                columns:["Sitio","Formulario", "De", "Para", "Estado", "Fecha", "Hora"],
                data:[]
            },
            pagination:{
                total:0,
                offset:0,
                limit:25
            }
        }
        },
        created() {
            
        },
        methods: {
            onPage(event) {
                LogsServices.onPage(this.selects.slected, event.firts, event.row).then((response)=>{
                  const result = response.data;
                  if(result.result == "success"){
                    const logsList = result.data.tracks.map((log)=>{
                        return {
                            track_record_id: log.track_record_id,
                            sitio: log.site,
                            formulario: log.from_name,
                            de: log.from,
                            para: log.to,
                            estado: log.success || log.fail,
                            fecha: log.date_formated,
                            hora: log.created_at
                        }
                    })
                    this.logsList.data = Object.assign([],logsList);
                    this.pagination.total = result.data.count;
                  }else{
                      this.$store.dispatch('alert_error',{message:'An error occurred, please try again later', time:1200}); 
                  }
                }).catch(error => {
                    if(error.response.status == 401){
                      this.$store.dispatch('alert_error',{message:'An error occurred, please try again later', time:1200}); 
                    }
                });
            },
            SelectIp(item){
                const credential = this.userStore.credentials[this.userStore.credentials.findIndex(credential=> credential.credential_id == this.selects.selected)];
                LogsServices.selectIp(this.selects.selected, this.pagination.offset, this.pagination.limit).then((response)=>{
                  const result = response.data;
                  if(result.result == "success"){
                    const logsList = result.data.tracks.map((log)=>{
                        return {
                            track_record_id: log.track_record_id,
                            sitio: log.site,
                            formulario: log.from_name,
                            de: log.from,
                            para: log.to,
                            estado: log.success || log.fail,
                            fecha: log.date_formated,
                            hora: this.formaterHora(log.created_at)
                        }
                    })
                    this.logsList.data = Object.assign([],logsList);
                    this.pagination.total = result.data.count;
                  }else{
                      this.$store.dispatch('alert_error',{message:'An error occurred, please try again later', time:1200}); 
                  }
                }).catch(error => {
                    if(error.response.status == 401){
                      this.$store.dispatch('alert_error',{message:'An error occurred, please try again later', time:1200}); 
                    }
                });
            },

            formaterHora(created_at){
                const format = created_at.substring(11,19)
                return format;
            }
        },
        computed: {
            userStore() {
                return this.$store.state.Session.user
            }
        }
    }
</script>
<style>
</style>
